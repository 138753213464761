import Head from "../components/Head";
import PageLayout from "../components/PageLayout";
import PetsIcon from "@material-ui/icons/Pets";
import { CountersOptions, getDate, useCounters } from "../hooks/useCounters";
import { createTheme } from "../hooks/createTheme";

export const MURPHY_COUNTERS: CountersOptions[] = [
  {
    primaryText: "Murphy has graced this earth for...",
    startTime: getDate("february,24,2024,00:00:00"),
  },
  {
    primaryText: "Murphy has been apart of the family for...",
    startTime: getDate("april,24,2024,09:00:00"),
  },
];

const MURPHY_THEME = createTheme({
  base: "#FFFFFF",
  icon: "#7D7D7D",
  text: "0, 0, 0",
});

export const MurphyPage = () => {
  return (
    <>
      <Head
        title="Murphy"
        description="Counting the days for Murphy"
        iconPrefix="dog"
      />
      <PageLayout
        useCounter={useCounters(MURPHY_COUNTERS)}
        theme={MURPHY_THEME}
        Icon={PetsIcon}
      />
    </>
  );
};
