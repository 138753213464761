import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { EeveePage } from "./pages/Eevee";
import { ErrorPage } from "./pages/Error";
import { FC } from "react";
import { FrissyPage } from "./pages/Frissy";
import { MurphyPage } from "./pages/Murphy";

const App: FC = () => {
  return (
    <Router>
      <CssBaseline />
      <Switch>
        <Route path="/" exact>
          <FrissyPage />
        </Route>
        <Route path="/eevee" exact>
          <EeveePage />
        </Route>
        <Route path="/murphy" exact>
          <MurphyPage />
        </Route>
        <Route>
          <ErrorPage />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
