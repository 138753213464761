import FavoriteIcon from "@material-ui/icons/Favorite";
import Head from "../components/Head";
import PageLayout from "../components/PageLayout";
import { CountersOptions, getDate, useCounters } from "../hooks/useCounters";
import { createTheme } from "../hooks/createTheme";

const STARTED_DATING = getDate("april,17,2016,04:15:00");

const ANNIVERSARY_COUNTERS: CountersOptions[] = [
  {
    primaryText: "We've been married...",
    startTime: getDate("june,26,2020,12:00:00"),
  },
  {
    primaryText: "We've been together...",
    startTime: STARTED_DATING,
  },
  {
    primaryText: "That's a total of...",
    secondaryText: "And counting...",
    startTime: STARTED_DATING,
    useTotals: true,
  },
];

const LOVE_THEME = createTheme({
  base: "#FF3232",
  icon: "#CC2022",
  text: "255, 255, 255",
});

export const FrissyPage = () => {
  return (
    <>
      <Head
        title="F + M"
        description="Counting the days of Frank and Missy's love"
        iconPrefix="heart"
      />
      <PageLayout
        useCounter={useCounters(ANNIVERSARY_COUNTERS)}
        theme={LOVE_THEME}
        Icon={FavoriteIcon}
      />
    </>
  );
};
