import Head from "../components/Head";
import PageLayout from "../components/PageLayout";
import PetsIcon from "@material-ui/icons/Pets";
import { CountersOptions, getDate, useCounters } from "../hooks/useCounters";
import { createTheme } from "../hooks/createTheme";

export const EEVEE_COUNTERS: CountersOptions[] = [
  {
    primaryText: "Eevee has graced this earth for...",
    startTime: getDate("november,29,2020,00:00:00"),
  },
  {
    primaryText: "Eevee has been apart of the family for...",
    startTime: getDate("january,24,2021,10:00:00"),
  },
];

const EEVEE_THEME = createTheme({
  base: "#272727",
  icon: "#7D7D7D",
  text: "255, 255, 255",
});

export const EeveePage = () => {
  return (
    <>
      <Head
        title="Eevee"
        description="Counting the days for Eevee"
        iconPrefix="dog"
      />
      <PageLayout
        useCounter={useCounters(EEVEE_COUNTERS)}
        theme={EEVEE_THEME}
        Icon={PetsIcon}
      />
    </>
  );
};
