import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

export interface ThemeOptions {
  base: string;
  icon: string;
  text: string;
}

export const createTheme = ({ base, icon, text }: ThemeOptions) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: base,
      },
      secondary: {
        main: icon,
      },
      background: {
        paper: base,
        default: base,
      },
      text: {
        primary: `rgba(${text}, 0.87)`,
        secondary: `rgba(${text}, 0.54)`,
        disabled: `rgba(${text}, 0.38)`,
        hint: `rgba(${text}, 0.38)`,
      },
    },
  });

  return responsiveFontSizes(theme, {
    factor: 3,
    breakpoints: ["md", "lg"],
  });
};
