import AdbIcon from "@material-ui/icons/Adb";
import Head from "../components/Head";
import { FC } from "react";
import { FloatingIcons } from "../components/FloatingIcons";
import { PageContainer } from "../components/PageContainer";
import { createTheme } from "../hooks/createTheme";
import { makeStyles, ThemeProvider, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  errorPage: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
});

const ERROR_THEME = createTheme({
  base: "#272727",
  icon: "#7D7D7D",
  text: "255, 255, 255",
});

export const ErrorPage: FC = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={ERROR_THEME}>
      <Head
        title="Resource Not Found"
        description="This page does not exist."
        iconPrefix="dog"
      />
      <FloatingIcons Icon={AdbIcon} />
      <PageContainer className={classes.errorPage}>
        <Typography variant="h3" component="h1">
          This is not the web page you are looking for...
        </Typography>
      </PageContainer>
    </ThemeProvider>
  );
};
