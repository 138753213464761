import { useRef } from "react";
import { TimeItem } from "../components/TimeGrid";

export const getDate = (date: string) => new Date(date).toISOString();

export type CountersOptions = Omit<TimeItem, "ref">;

export const useCounters = (counters: CountersOptions[]) => {
  return () => counters.map(useCounter);
};

const useCounter = (counter: CountersOptions) => {
  return {
    ...counter,
    ref: useRef<HTMLDivElement>(null),
  };
};
